import axios from "axios";
import url from "../url";
import { getArray, setQuery, getJson, setData, setComingSoonData, getComingSoonArray, getComingSoonJson } from "../resources/storeItemResource";

export default class PlayListRepository {
    constructor(config = {}) {
        axios.defaults.errorHandle = config.errorHandle;
    }
    async slideShowIndex(data) {
        const params = setQuery(data);
        const response = await axios.get("api/admin-store/items", { params });
        if (response.status === 200) {
            return getArray(response.data);
        }
    }
    async index(data) {
        const params = setQuery(data);
        const response = await axios.get("api/admin-store/slideshow/all-list", { params });
        if (response.status === 200) {
            return getArray(response.data);
        }
    }
    async storeItemIndex(data) {
        const params = setQuery(data);
        const response = await axios.get("api/admin-store/items", { params });
        if (response.status === 200) {
            return getArray(response.data);
        }
    }
    async comingSoonIndex(data) {
        const params = setQuery(data);
        const response = await axios.get("api/admin-coming-soon-all", { params });
        if (response.status === 200) {
            return getComingSoonArray(response.data);
        }
    }
    async activeIndex(data) {
        const response = await axios.get("api/admin-store/slideshow/active/list");
        if (response.status === 200) {
            return response;
        }
    }
    async activeComingSoonIndex(data) {
        const response = await axios.get("api/admin-coming-soon-active");
        if (response.status === 200) {
            return response;
        }
    }
    async show(SlideshowId) {
        const response = await axios.get(url("showStoreSlideshow", { slideshow: SlideshowId }));
        if (response.status === 200) {
            return getJson(response.data.data);
            // return response.data.data
        }
    }
    async showComingSoon(ComingsoonId) {
        const response = await axios.get(url("showComingSoon", { comingsoon: ComingsoonId }));
        if (response.status === 200) {
            return getComingSoonJson(response.data.data);
            // return response.data.data
        }
    }

    async addSlideShow(data) {
        // const json = setData(data);
        const response = await axios.post(url("addStoreSlideShow"), data);
        if (response.status === 201) {
            return getJson(response.data);
        }
    }
    async addComingSoon(data) {
        const json = setComingSoonData(data);
        const response = await axios.post(url("storeComingSoon"), json);
        if (response.status === 201) {
            return getJson(response.data);
        }
    }
    async addActiveSlideShow(data) {
        const response = await axios.post("api/admin-store/slideshow/active/list", data);
        if (response.status === 201) {
            return response.data;
        }
    }
    async addActiveComingSoons(data) {
        const response = await axios.post("api/admin-coming-soon-active", data);
        if (response.status === 201) {
            return response.data;
        }
    }

    async update(id, data) {
        const json = setData(data);
        const response = await axios.put(url("updateStoreSlideshow", { slideshow: id }), json);
        if (response.status === 200) {
            return response.data.data;
        }
    }
    async comingSoonUpdate(id, data) {
        const json = setComingSoonData(data);
        const response = await axios.put(url("updateComingSoon", { comingsoon: id }), json);
        if (response.status === 200) {
            return response.data.data;
        }
    }

    async updateSort(playlistId) {
        const response = await axios.put(url("updateSortPlaylist", { playlist: playlistId }));
        if (response.status === 200) {
            return response.data.data;
        }
    }

    async delete(id) {
        const response = await axios.delete(url("deleteStoreSlideshow", { slideshow: id }));
        if (response.status === 200) {
            return true;
        }
    }
    async deleteComingSoon(id) {
        const response = await axios.delete(url("deleteComingSoon", { comingsoon: id }));
        if (response.status === 200) {
            return true;
        }
    }
    async storeStoreItem(data) {
        const response = await axios.post(url("storeStoreItem"), data);
        if (response.status === 201) {
            return response;
        }
    }
    async showStoreItem(storeId) {
        const response = await axios.get(url("showStoreItem", { storeId: storeId }));
        if (response.status === 200) {
            return response.data.data;
        }
    }
    async showMainStoreItem(storeId) {
        const response = await axios.get(url("showMainStoreItem", { storeId: storeId }));
        if (response.status === 200) {
            return response.data.data;
        }
    }
    async updateStoreItem(id, data) {
        // const json = setData(data);
        const response = await axios.put(url("updateStoreItem", { item: id }), data);
        if (response.status === 200) {
            return response.data.data;
        }
    }
    async addNewSticker(data) {
        const response = await axios.post(url("addNewStickerToStoreItem"), data);
        if (response.status === 201) {
            return response;
        }
    }
    async showSticker(stickerId) {
        const response = await axios.get(url("showStoreItemSticker", { item: stickerId }));
        if (response.status === 200) {
            return response.data.data;
        }
    }
    async editSticker(id, data) {
        const response = await axios.put(url("updateStoreItemSticker", { item: id }), data);
        if (response.status === 200) {
            return response.data.data;
        }
    }
    async deleteSticker(id) {
        const response = await axios.delete(url("deleteStoreItemSticker", { item: id }));
        if (response.status === 200) {
            return true;
        }
    }
    async stickerSendToTop(id) {
        const response = await axios.put(url("stickerSendToTop", { item: id }));
        if (response.status === 200) {
            return true;
        }
    }
    async packSendToTop(id) {
        const response = await axios.put(url("packSendToTop", { item: id }));
        if (response.status === 200) {
            return true;
        }
    }
}
